import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "blog-posts dates" }
const _hoisted_2 = ["datetime"]

import { formatDate } from '@/common/utils/formatDate'
import { loadBlogPosts } from './loadBlogPosts'
import { useAppContext } from '@/web/AppContext'


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogPageList',
  async setup(__props) {

let __temp: any, __restore: any

const appContext = useAppContext()
const blogPosts = (
  ([__temp,__restore] = _withAsyncContext(() => loadBlogPosts(appContext))),
  __temp = await __temp,
  __restore(),
  __temp
)

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blogPosts), (blogPost) => {
      return (_openBlock(), _createElementBlock("li", {
        key: blogPost.slug
      }, [
        _createElementVNode("time", {
          datetime: _unref(formatDate)(blogPost.createdAt)
        }, _toDisplayString(_unref(formatDate)(blogPost.createdAt)), 9 /* TEXT, PROPS */, _hoisted_2),
        _createVNode(_component_router_link, {
          to: `${blogPost.slug}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(blogPost.title), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})