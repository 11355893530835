import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container text-container full" }
const _hoisted_2 = { class: "flex-vgap" }

import { useSeoMeta } from '@unhead/vue'
import BlogList from '../Blog/BlogPageList.vue'

const title = '404: Page Not Found'

export default /*@__PURE__*/_defineComponent({
  __name: '404Page',
  setup(__props) {

useSeoMeta({ title })

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(title)),
    _createElementVNode("section", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, " Pages ", -1 /* HOISTED */)),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Home")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/about" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("About")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/blog" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Blog")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/projects" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Projects")
            ])),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("h2", null, " Blog ", -1 /* HOISTED */)),
      _createVNode(BlogList)
    ])
  ]))
}
}

})